import './UserLanguage.scss';
import { TranslationKey } from '@tolgee/react';
import React, { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useAction } from 'src/hooks/useAction';
import { Dropdown } from 'src/modules/common/components/Dropdown';
import { LANGUAGE_LIST } from 'src/modules/common/constants/language';
import { GlobeIcon } from 'src/modules/common/icons/GlobeIcon';
import { CONFIG_LANGUAGE_SWITCH } from 'src/modules/config/actions/ConfigActions';
import { getEnvironmentLanguages } from 'src/modules/config/selectors/getEnvironmentLanguages';
import { getLanguage } from 'src/modules/config/selectors/getLanguage';
import { Language } from 'src/types/common/Language';

export const UserLanguage = memo(() => {
  const intl = useIntl();

  const language = useSelector(getLanguage);
  const languages = useSelector(getEnvironmentLanguages);

  const switchLanguage = useAction(CONFIG_LANGUAGE_SWITCH.request);

  return (
    <Dropdown
      options={LANGUAGE_LIST}
      renderLabel={(option) => <FormattedMessage id={LANGUAGE_KEYS[option]}/>}
      selectedValue={language}
      onChange={switchLanguage}
      icon={<GlobeIcon/>}
      shaded={false}
      size="xlg"
    />
  );
});

const LANGUAGE_KEYS: Record<Language, TranslationKey> = {
  'en-US': 'header/user/language/en-US',
  'de-CH': 'header/user/language/de-CH',
  'fr-FR': 'header/user/language/fr-FR',
  'hu-HU': 'header/user/language/hu-HU',
  'es-ES': 'header/user/language/es-ES',
  'sv-SE': 'header/user/language/sv-SE',
  'zh-CN': 'header/user/language/zh-CN',
  'it-IT': 'header/user/language/it-IT',
};
